.footer {
  // border: 1px solid red;
  .map {
    position: relative;
    height: 600px;
    .overflow-layer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 600px;
      background: rgba(0,0,0,.2);
      z-index: 1;
      transition: opacity .2s ease-in-out .2s;
    }
  }
  .footer__bottom {
    position: relative;
    display: flex;
    flex-direction: column;
    background: #000;
    z-index: 1;
    .contacts {
      display: flex;
      margin: -40px auto 0 auto;
      width: 100%;
      max-width: 1300px;
      .contacts__item {
        display: flex;
        align-items: center;
        padding: 20px 30px;
        width: 100%;
        .item__img {
          width: auto;
          height: 45px;
        }
        .item__text {
          margin-left: 20px;
          color: #fff;
          font-size: 18px;
          a {
            color: #fff;
          }
        }
      }
      .contacts__item:nth-child(1) {
        background: #c51718;
      }
      .contacts__item:nth-child(2) {
        background: #e3470c;
        .item__text {
          font-size: 20px;
        }
      }
      .contacts__item:nth-child(3) {
        background: #ff7300;
      }
    }
    .copyright {
      margin: 20px 0;
      .copyright__text {
        text-align: center;
        color: #fff;
        font-size: 14px;
        margin-bottom: 5px;
        padding: 0;
      }
      .copyright__text:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@include for (1300) {
  .footer {
    .footer__bottom {
      .contacts {
        margin-top: 0;
        .contacts__item {
          width: auto;
          flex: 1 1 auto;
        }
      }
    }
  }
}

@include for (950) {
  .footer {
    .map {
      height: 400px;
    }
    .footer__bottom {
      .contacts {
        flex-direction: column;
        // align-items: center;
        .contacts__item {
          display: flex;
          justify-content: center;
          width: 100%;
          padding: 20px;
          .item__img {
            width: 46px;
          }
          .item__text {
            width: 100%;
            max-width: 270px;
          }
        }
      }
    }
  }
}
