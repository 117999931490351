.advantage__wrapper {
  // border: 1px solid red;
  position: relative;
  box-shadow: 0 0 15px 2px rgba(0,0,0,.4);
  .advantage {
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    padding: 60px 0;
    width: 100%;
    max-width: 1600px;
    .advantage__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 30px;
      width: 100%;
      .item__img {
        width: auto;
        height: 45px;
      }
      .item__title {
        margin-top: 30px;
        max-width: 230px;
        text-align: center;
        font-family: 'CenturyGothic', Arial, Helvetica, sans-serif;
        font-size: 20px;
        font-weight: 600;
      }
      .item__description {
        margin-top: 20px;
        text-align: center;
      }
    }
  }

}

@include for (1200) {
  .advantage__wrapper {
    .advantage {
      flex-wrap: wrap;
      .advantage__item {
        margin-top: 40px;
        width: 50%;
      }
    }
  }
}

@include for (700) {
  .advantage__wrapper {
    .advantage {
      padding: 40px 0;
      .advantage__item {
        width: 100%;
      }
      .advantage__item:first-child {
        margin-top: 0;
      }
    }
  }
}
