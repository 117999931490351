.software {
  display: flex;
  flex-direction: column;
  background: #ebebeb;
  padding: 60px 0;

  .software__block-title {
    align-self: center;
    font-family: 'CenturyGothic', Arial, Helvetica, sans-serif;
    font-size: 45px;
    font-weight: 600;
  }

  .software__items {
    // border: 1px solid red;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    margin-top: 40px;
    width: 100%;
    max-width: 1100px;

    .item {
      border: 1px solid red;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 40px 20px;
      width: calc(33.33% - 7px);
      background: #fff;
      border: 1px solid rgba(0, 0, 0, .1);
      box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, .2);
      transition: all .2s ease-in-out;

      .item__img {
        width: auto;
        height: 110px;
      }

      .item__title {
        margin-top: 30px;
        padding: 0 10px;
        text-align: center;
        color: #000;
        font-family: 'CenturyGothic', Arial, Helvetica, sans-serif;
        font-size: 24px;
        font-weight: 600;
        transition: all .2s ease-in-out;
      }

      .item__description {
        margin-top: 30px;
        text-align: center;
        color: #000;
        font-size: 18px;
        font-weight: 200;
      }

      .item__cost {
        margin-top: 40px;
        color: #c51718;
        font-size: 20px;
        font-weight: 700;
      }

      .item__more-info-text {
        margin-top: 20px;
        color: #000;
        font-size: 18px;
        font-weight: 200;
        transition: all .2s ease-in-out;
      }
    }

    .item:nth-child(3n) {
      margin-right: 0;
    }

    .item:hover {
      border: 1px solid transparent;
      box-shadow: none;

      .item__title {
        color: #c51718;
      }

      .item__more-info-text {
        color: #ff7300;
      }
    }
  }

  .newSoftware__items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 100%!important;
      picture {
        img.item__img {
          width: 150px;
          height: 150px;
          object-fit: cover;
          border-radius: 50%;
        }
      }
    }
  }
}

@include for (1150) {
  .software {
    padding: 60px 20px;
    .software__items {
      .item {
        width: calc(50% - 5px);
      }
      .item:nth-child(3n) {
        margin-right: 10px;
      }
      .item:nth-child(2n) {
        margin-right: 0;
      }
    }
    .newSoftware__items {
      width: 100%;
      margin: 0!important;
    }
  }
}

@include for (700) {
  .software {
    .software__block-title {
      padding: 0 10px;
      text-align: center;
      font-size: 30px;
    }
    .software__items {
      .item {
        width: 100%;
        box-shadow: 0 0 6px 0px rgba(0,0,0,.2);
      }
      .item:nth-child(1n) {
        margin-right: 0;
      }
    }
  }
}

@include for (700) {
  .software {
    .newSoftware__items {
      display: grid;
      grid-template-columns: 1fr;
      gap: 30px;
    }
  }
}
