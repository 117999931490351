.send-question {
  // border: 1px solid red;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 60px 0;
  background: url(/assets/templates/images/send-question/send-question-bg.jpg);
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  z-index: 1;

  .send-question__block-title {
    align-self: center;
    text-align: center;
    color: #fff;
    font-family: 'CenturyGothic', Arial, Helvetica, sans-serif;
    font-size: 45px;
    font-weight: 600;
  }

  .send-question__form {
    display: flex;
    flex-wrap: wrap;
    margin: 40px auto 0 auto;
    width: 100%;
    max-width: 770px;
    .form-group {
      display: flex;
      flex-direction: column;
      width: calc(50% - 15px);
      margin-bottom: 10px;
      span {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        padding: 0 10px;
        color: #ffffff;
        span {
          margin-bottom: 0;
        }
      }
      .form__input {
        padding: 10px;
        width: 100%;
        background: transparent;
        border: 2px solid #fff;
        border-radius: 20px;
        color: #fff;
        font-weight: 200;
        transition: all .2s ease-in-out;
      }
      .form__input:focus {
        border: 2px solid #ff7300;
      }
      .form__input::placeholder {
        color: #fff;
      }
      .form__textarea {
        padding: 10px;
        width: 100%;
        min-height: 100px;
        background: transparent;
        border: 2px solid #fff;
        border-radius: 20px;
        color: #fff;
        font-weight: 200;
        transition: all .2s ease-in-out;
      }
      .form__textarea:focus {
        border: 2px solid #ff7300;
      }
      .form__textarea::placeholder {
        color: #fff;
      }
    }
    .form-group:nth-child(3) {
      margin-left: 30px;
    }
    .form-group:nth-child(4) {
      width: 100%;
    }
    .hidden {
      display: none;
    }

    .form__action-btn {
      margin-top: 10px;
      margin-left: auto;
      margin-right: auto;
      padding: 10px 20px;
      background: transparent;
      color: #fff;
      font-size: 20px;
      border: none;
      transition: all .2s ease-in-out;
    }
    .form__action-btn:hover {
      color: #ff7300;
    }
  }
}

@include for (800) {
  .send-question {
    padding: 60px 20px;
  }
}

@include for (700) {
  .send-question {
    .send-question__block-title {
      padding: 0 10px;
      text-align: center;
      font-size: 30px;
    }
    .send-question__form {
      .form-group {
        width: 100%;
      }
      .form-group:nth-child(3) {
        margin-left: 0;
      }
      .form__input {
        width: 100%;
      }
      .form__input:first-child {
        margin-right: 0;
      }
    }
  }
}
