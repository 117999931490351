
//#jGrowl {
//  font-size:18px;
//  margin:0px 12px 0;
//}
.jGrowl.top-right {
  right: 40px !important;
  top: 20px !important;
}
#jGrowl .jGrowl-notification {
  position: relative;
  width: 100%;
  max-width: 360px;
  padding: 15px 25px 15px 15px;
  box-shadow: 2px 2px 4px 1px rgba(0,0,0,.2) !important;
  border: 2px solid #fff !important;
  border-radius: 10px;
}
#jGrowl .jGrowl-closer {
  padding: 10px;
  width: 100%;
  max-width: 360px;
  box-shadow: 2px 2px 4px 1px rgba(0,0,0,.2) !important;
  border: 2px solid #fff !important;
  border-radius: 10px;
}
#jGrowl .jGrowl-notification .jGrowl-close {
  position: absolute;
  right: 4px;
  top: 4px;
  font-size: 16px;
  color:  #fff !important;
}
#jGrowl .jGrowl-notification .jGrowl-message {
  font-size: 18px;
  color: #fff !important;
}
#jGrowl .af-message-error {
  background:  #c51718;
}
#jGrowl .af-message-success {
  background: #8ab933 !important;
}