.services {
  // border: 1px solid red;
  position: relative;
  box-shadow: 0 0 15px 2px rgba(0,0,0,.4);
  .services__top {
    position: relative;
    display: flex;
    margin: -40px auto 0 auto;
    width: 100%;
    max-width: 1600px;
    .services__top-item {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 30px;
      cursor: pointer;
      .top-item__icon {
        width: auto;
      }
      .top-item__title {
        margin-left: 20px;
        text-transform: uppercase;
        color: #fff;
        font-size: 24px;
      }
      .top-item__links {
        position: absolute;
        bottom: 80%;
        left: 0;
        width: 100%;
        // height: auto !important;
        visibility: hidden;
        opacity: 0;
        transition: all .2s ease-in-out;
        .links__item {
          display: flex;
          .item__link {
            // border: 1px solid red;
            flex: 1 1 auto;
            padding: 20px 80px;
            text-transform: uppercase;
            color: #fff;
            font-size: 20px;
            transition: all .2s ease-in-out;
          }
        }
        .links__item:hover {
          .item__link {
            color:#ff7300;
          }
        }
      }
    }
    .services__top-item:nth-child(1) {
      background: #c51718;
      .top-item__links {
        background: linear-gradient(to bottom, rgba($color: #000, $alpha: .9), rgba($color: #c51718, $alpha: .9));
      }
    }
    .services__top-item:nth-child(2) {
      background: #e3470c;
      .top-item__links {
        background: linear-gradient(to bottom, rgba($color: #000, $alpha: .9), rgba($color: #e3470c, $alpha: .9));
      }
    }
    .services__top-item:nth-child(3) {
      background: #ff7300;
      .top-item__links {
        background: linear-gradient(to bottom, rgba($color: #000, $alpha: .9), rgba($color: #ff7300, $alpha: .9));
      }
    }
    .services__top-item:hover {
      .top-item__links {
        bottom: 100%;
        visibility: visible;
        opacity: 1;
      }
    }
  }
  .services__main-content {
    display: flex;
    flex-direction: column;
    padding: 60px 0;
    .main-content__block-title {
      align-self: center;
      font-family: 'CenturyGothic', Arial, Helvetica, sans-serif;
      font-size: 45px;
      font-weight: 600;
    }
    .main-content__services-items {
      // border: 1px solid red;
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      margin-top: 40px;
      width: 100%;
      max-width: 1000px;
      &.center {
        text-align: center;
      }
      .item {
        // border: 1px solid red;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        margin-bottom: 10px;
        padding: 40px;
        width: calc(33.33% - 7px);
        border: 1px solid transparent;
        transition: all .2s ease-in-out;
        .item__icon {
          width: auto;
          height: 60px;
        }
        .item__title {
          margin-top: 30px;
          color: #000;
          font-family: 'CenturyGothic', Arial, Helvetica, sans-serif;
          font-size: 24px;
          font-weight: 600;
          transition: all .2s ease-in-out;
        }
        .item__more-info-text {
          margin-top: 20px;
          color: #000;
          font-size: 18px;
          font-weight: 200;
          transition: all .2s ease-in-out;
        }
      }
      .item:nth-child(3n) {
        margin-right: 0;
      }
      .item:hover {
        border: 1px solid rgba(0,0,0,.1);
        box-shadow: 2px 2px 6px 0px rgba(0,0,0,.2);
        .item__title {
          color: #c51718;
        }
        .item__more-info-text {
          color: #ff7300;
        }
      }
    }
  }
}

@include for (1040) {
  .services {
    .services__main-content {
      padding: 60px 20px;
    }
  }
}

@include for (1000) {
  .services {
    .services__main-content {
      .main-content__services-items {
        // border: 1px solid red;
        .item {
          width: calc(50% - 5px);
        }
        .item:nth-child(3n) {
          margin-right: 10px;
        }
        .item:nth-child(2n) {
          margin-right: 0;
        }

      }
    }
  }
}

@include for (960) {
  .services {
    .services__top {
      margin-top: 0;
      flex-direction: column;
      .services__top-item {
        padding: 15px 30px;
        transition: all .2s ease-in-out;
        .top-item__links {
          top: calc(100% + 1px);
          overflow: hidden;
          z-index: 1;
        }
      }
      .services__top-item--active {
        .top-item__links {
          visibility: visible;
          opacity: 1;
        }
      }
      .services__top-item:nth-child(1) {
        background: #c51718;
        .top-item__links {
          background: linear-gradient(to top, rgba($color: #000, $alpha: .9), rgba($color: #c51718, $alpha: .9));
        }
      }
      .services__top-item:nth-child(2) {
        background: #e3470c;
        .top-item__links {
          background: linear-gradient(to top, rgba($color: #000, $alpha: .9), rgba($color: #e3470c, $alpha: .9));
        }
      }
      .services__top-item:nth-child(3) {
        background: #ff7300;
        .top-item__links {
          background: linear-gradient(to top, rgba($color: #000, $alpha: .9), rgba($color: #ff7300, $alpha: .9));
        }
      }
    }
  }
}

@include for (700) {
  .services {
    .services__main-content {
      .main-content__block-title {
        padding: 0 10px;
        text-align: center;
        font-size: 30px;
      }
      .main-content__services-items {
        // border: 1px solid red;
        .item {
          width: 100%;
          border: 1px solid rgba(0,0,0,.1);
          box-shadow: 0 0 6px 0px rgba(0,0,0,.2);
        }
        .item:nth-child(1n) {
          margin-right: 0;
        }
        .item:hover {
          border: 1px solid rgba(0,0,0,.1);
          box-shadow: 0 0 6px 0px rgba(0,0,0,.2);
        }
      }
    }
  }
}
