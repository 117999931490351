.contact-us__request-call {
  // border: 1px solid red;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  .request-call__block-title {
    align-self: center;
    text-align: center;
    color: #fff;
    font-family: 'CenturyGothic', Arial, Helvetica, sans-serif;
    font-size: 45px;
    font-weight: 600;
  }

  .request-call__form {
    display: flex;
    flex-wrap: wrap;
    margin: 40px auto 0 auto;
    width: 100%;
    max-width: 770px;
    .form-group {
      display: flex;
      flex-direction: column;
      width: calc(50% - 15px);
      margin-bottom: 10px;
      span {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        padding: 0 10px;
        color: #ffffff;
        span {
          margin-bottom: 0;
        }
      }
      .form__input {
        padding: 10px;
        width: 100%;
        background: transparent;
        border: 2px solid #fff;
        border-radius: 20px;
        color: #fff;
        font-weight: 200;
        transition: all .2s ease-in-out;
      }
      .form__input:focus {
        border: 2px solid #ff7300;
      }
      .form__input::placeholder {
        color: #fff;
      }
      .form__textarea {
        padding: 10px;
        width: 100%;
        min-height: 150px;
        background: transparent;
        border: 2px solid #fff;
        border-radius: 20px;
        color: #fff;
        font-weight: 200;
        transition: all .2s ease-in-out;
      }
      .form__textarea:focus {
        border: 2px solid #ff7300;
      }
      .form__textarea::placeholder {
        color: #fff;
      }
    }
    .form-group:nth-child(3) {
      margin-left: 30px;
    }
    .form-group:nth-child(4) {
      width: 100%;
    }
    .hidden {
      display: none;
    }

    .form__action-btn {
      margin-top: 10px;
      margin-left: auto;
      margin-right: auto;
      padding: 10px 30px;
      background: #ff7300;
      color: #fff;
      font-size: 20px;
      border: none;
      border-radius: 25px;
      transition: all .2s ease-in-out;
    }
    .form__action-btn:hover {
      //color: #ff7300;
      background: #c51718;
    }
  }
}

@include for (600) {
  .contact-us__request-call {
    .request-call__block-title {
      font-size: 30px;
    }
    .request-call__form {
      margin-top: 30px;
      .form-group {
        width: 100%;
      }
      .form-group:nth-child(3) {
        margin-left: 0;
      }
    }
  }
}
