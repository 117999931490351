.additional-info {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 60px 20px;
  background: #ffffff;
  box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.4);
  z-index: 2;
  .additional-info__block-title {
    align-self: center;
    margin-bottom: 40px;
    text-align: center;
    font-family: 'CenturyGothic', Arial, Helvetica, sans-serif;
    font-size: 45px;
    font-weight: 600;
  }
  .additional-info__content {
    margin: 0 auto;
    width: 100%;
    max-width: 1300px;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
  }
}

@include for (700) {
  .additional-info {
    .additional-info__block-title {
      padding: 0 10px;
      text-align: center;
      font-size: 30px;
    }
  }
}
