.header {
   //border: 1px solid red;
  position: relative;
  display: flex;
  flex-direction: column;
  background: #fff;
  z-index: 9;
  .header__top {
    border-bottom: 1px solid #737373;
    transition: all .2s ease-in-out;

    .burger-btn__wrapper {
      // border: 1px solid red;
      align-self: flex-end;
      position: relative;
      display: none;
      margin: 10px;
      width: 30px;
      height: 30px;
      cursor: pointer;
      .burger-btn {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 2px;
        transform: translateY(-50%);
        background: #c51719;
        transition: all .2s ease-in-out;
      }
      .burger-btn:before {
        position: absolute;
        content: '';
        top: 7px;
        left: 0;
        width: 100%;
        height: 2px;
        background: #c51719;
        transition: all .2s ease-in-out;
      }
      .burger-btn:after {
        position: absolute;
        content: '';
        top: -7px;
        left: 0;
        width: 100%;
        height: 2px;
        background: #c51719;
        transition: all .2s ease-in-out;
      }
      .burger-btn--active {
        height: 0;
      }
      .burger-btn--active:before {
        top: -1px;
        transform: rotate(-45deg);
      }
      .burger-btn--active:after {
        top: -1px;
        transform: rotate(45deg);
      }
    }

    .header__top-content {
      // border: 1px solid red;
      display: flex;
      align-items: center;
      margin: 0 auto;
      width: 100%;
      max-width: 1600px;

      .main-menu {
        .main-menu__list {
          display: flex;

          .main-menu__list-item {
            display: flex;

            .list-item__link {
              padding: 10px 15px;
              text-transform: uppercase;
              color: #000;
              font-family: 'MyriadPro', Arial, Helvetica, sans-serif;
              font-size: 14px;
              font-weight: 600;
              transition: all .2s ease-in-out;
            }
            .list-item__link:hover {
              color: #c51719;
            }
          }
        }
      }

      .search {
        // border: 1px solid red;
        margin: 10px 0;
        position: relative;
        margin-left: auto;
        width: 100%;
        max-width: 370px;

        .search__input {
          padding: 5px 20px;
          width: 100%;
          border: 1px solid #737373;
          border-radius: 20px;
        }

        .search__action-btn {
          position: absolute;
          top: 50%;
          right: 20px;
          transform: translateY(-50%);
          padding: 0;
          width: 20px;
          height: 20px;
          background: transparent;
          border: none;
          overflow: hidden;
          cursor: pointer;

          .action-btn__icon {
            width: 15px;
            height: 15px;
          }
        }
      }

      .social-links {
        .social-link {
          margin-left: 10px;
          .social-link__icon {
            width: 25px;
            height: 25px;
            fill: #737373;
            transition: all .2s ease-in-out;
          }
          .social-link__icon:hover {
            fill: #c51719;
          }
        }
        .social-link:first-child {
          margin-left: 120px;
        }
      }
    }
  }

  .header__center {
    // border: 1px solid red;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
    max-width: 1600px;

    .company-logo {
      max-width: 280px;
    }

    .contacts {
      display: flex;
      padding: 20px 0;
      .contacts__right-block, .contacts__left-block {
        .contacts__item {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          .item__icon-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            .item__icon {
              width: auto;
            }
          }
          .item__text {
            margin-left: 10px;
            color: #000;
            font-weight: 300;
            padding: 0;
          }
        }
      }
      .contacts__right-block {
        margin-left: 80px;
        .contacts__item {
          .item__text {
            font-size: 20px;
            font-weight: 400;
            margin-right: 10px;
          }
        }
        .contacts__callback {
          //padding: 10px 35px;
          //border: 2px solid #c51719;
          //border-radius: 20px;
          //text-align: center;
          //transition: all .2s ease-in-out;
          //cursor: pointer;
          .callback__btn {
            padding: 10px 35px;
            border: 2px solid #c51719;
            border-radius: 20px;
            text-align: center;
            transition: all .2s ease-in-out;
            cursor: pointer;
            text-transform: uppercase;
            background: transparent;
            color: #c51719;
            font-size: 14px;
            font-weight: 600;
          }
          .callback__btn:hover {
            background: #c51719;
            color: #fff;
          }
        }
        //.contacts__callback:hover {
        //  background: #c51719;
        //  .callback__text {
        //    color: #fff;
        //  }
        //}
      }
    }

    .additional-logo {
      max-width: 155px;
    }
  }

  .header__bottom {
    // border: 1px solid red;
    background: #000;
    border-bottom: 2px solid #ff7300;
    .additional-menu {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin: 0 auto;
      width: 100%;
      max-width: 1600px;
      .additional-menu__item {
        padding: 20px;
        text-transform: uppercase;
        text-align: center;
        color: #fff;
        font-size: 20px;
        transition: all .2s ease-in-out;
        cursor: pointer;
        a {
          color: #fff;
          transition: all .2s ease-in-out;
        }
      }
      .additional-menu__item:hover {
        color: #ff7300;
        a {
          color: #ff7300;
        }
      }
    }
  }
}

.form-polit {
  display: grid;
  grid-template-columns: 16px 1fr;
  color: #fff;
  gap: 10px;
  width: 100%;
  a {
    color: #fff;
    text-decoration: underline;
  }
}

@include for (1640) {
  .header {
    .header__top {
      padding: 0 20px;
    }
    .header__center {
      justify-content: space-around;
      .company-logo {
        width: 50%;
      }
      .contacts {
        flex-direction: column;
        .contacts__left-block {
          .contacts__item:last-child {
            margin-bottom: 3px;
          }
        }
        .contacts__right-block {
          margin-left: 0;
          .contacts__item {
            .item__text {
              margin-left: 10px;
              color: #000;
              font-size: 16px;
              font-weight: 300;
            }
          }
        }
      }
      .additional-logo {
        width: 50%;
      }
    }
  }
}

@include for (1390) {
  .header {
    .header__top {
      .header__top-content {
        .main-menu {
          .main-menu__list {
            .main-menu__list-item {
              .list-item__link {
                padding: 10px 5px;
              }
            }
          }
        }
      }
    }
  }
}

@include for (1130) {
  .header {
    .header__top {
      .header__top-content {
        .main-menu {}
        .search {}
        .social-links {
          .social-link:first-child {
            margin-left: 20px;
          }
        }
      }
    }
    .header__center {
      padding: 0 20px;
      justify-content: space-between;
    }
    .header__bottom {
      display: none;
    }
  }
}

@include for (940) {
  .header {
    .header__top {
      position: fixed;
      top: 10px;
      right: 10px;
      padding: 0;
      width: 50px;
      height: 50px;
      background: #fff;
      border: none;
      border-radius: 10px;
      box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
      z-index: 9999;
      overflow-x: hidden;
      overflow-y: scroll;
      .burger-btn__wrapper {
        display: flex;
        margin-left: auto;
      }
      .header__top-content {
        display: none;
        flex-direction: column;
        margin-top: 50px;
        height: calc(100% - 100px);
        .main-menu {
          order: 2;
          .main-menu__list {
            flex-direction: column;
            align-items: center;
            .main-menu__list-item {
              margin-bottom: 20px;
              .list-item__link {
                font-size: 20px;
              }
            }
            .main-menu__list-item:last-child {
              margin-bottom: 0;
            }
          }
        }
        .search {
          order: 1;
          width: 100%;
          margin: 0 0 40px 0;
          .search__input {
            padding: 10px 30px;
            width: 100%;
          }
        }
        .social-links {
          order: 3;
          margin: 40px 0;
          .social-link:first-child {
            margin-left: 0;
          }
        }
      }
    }
    .header__top--active {
      top: 0;
      right: 0;
      width: 100%;
      height: 100vh;
      border-radius: 0;
      box-shadow: none;
    }
    .header__center {
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 20px;
      .company-logo {
        order: 1;
      }
      .additional-logo {
        order: 2;
        margin-left: 100px;
      }
      .contacts {
        order: 3;
        width: 100%;
        align-items: center;
        margin-top: 20px;
        .contacts__left-block, .contacts__right-block {
          display: flex;
          flex-direction: column;
          align-items: center;
          .contacts__item {
            .item__icon-wrapper {
              display: none;
            }
            .item__icon-wrapper.viber {
              display: block;
            }
            .item__text {
              text-align: center;
            }
          }
        }
        .contacts__right-block {
          .contacts__item {
            .item__text {
              margin-top: 15px;
              font-size: 20px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

@include for (700) {
  .header {
    .header__top {
      .header__top-content {
        .search {
          width: calc(100% - 40px);
        }
      }
    }
    .header__center {
      padding-top: 40px;
      padding-bottom: 20px;
      .company-logo {
        display: flex;
        justify-content: center;
        width: 100%;
        a img {
          width: 220px;
        }
      }
      .additional-logo {
        display: none;
      }
    }
  }
}
