.maintenance {
  // border: 1px solid red;
  display: flex;
  flex-direction: column;
  padding: 60px 0;
  background: #ebebeb;

  .maintenance__block-title {
    align-self: center;
    font-family: 'CenturyGothic', Arial, Helvetica, sans-serif;
    font-size: 45px;
    font-weight: 600;
  }

  .maintenance__tabs-wrapper {
    margin-top: 40px;
    .tab-headers {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 0 60px 20px 60px;
      border-bottom: 1px solid #ff7300;
      overflow: hidden;
      .tab-headers__triangle {
        position: absolute;
        bottom: -7px;
        left: 0;
        width: 15px;
        height: 15px;
        background: #ff7300;
        transform: rotate(-45deg);
        transition: all .2s ease-in-out;
      }
      .tab-header__element {
        position: relative;
        display: flex;
        align-items: center;
        padding: 10px 40px;
        cursor: pointer;
        .element__icon {
          color: #737373;
          font-size: 26px;
          transition: all .2s ease-in-out;
        }
        .element__text {
          margin-left: 10px;
          font-size: 20px;
          transition: all .2s ease-in-out;
        }
        .element__accordion-content-wrapper {
          display: none;
        }
      }
      .tab-header__element:hover {
        .element__icon {
          color: #ff7300;
        }
        .element__text {
          color: #ff7300;
        }
      }
      .tab-header__element--active {
        .element__icon {
          color: #ff7300;
        }
        .element__text {
          color: #ff7300;
        }
      }
    }
    .tab-contents {
      // border: 1px solid red;
      margin: 0 auto;
      padding: 60px 0 0 0;
      width: 100%;
      max-width: 1300px;
      transition: all .2s ease-in-out;
      .tab-content__element {
        display: none;
        .element__list {
          .list__item {
            display: flex;
            margin-bottom: 20px;
            .item__text {
              font-size: 22px;
            }
            .divider {
              flex: 1 1 auto;
              margin: 0 10px;
              min-width: 40px;
              height: 18px;
              border-bottom: 3px dotted #737373;
            }
            .item__cost {
              color: #c51718;
              font-size: 22px;
              font-weight: 700;
            }
          }
          .list__item:last-child {
            margin-bottom: 0;
          }
        }
      }
      .tab-content__element:first-child {
        display: block;
      }
    }
  }

  ul {
    list-style: none;
  }
  ul li {
    position: relative;
    padding-left: 30px;
    font-size: 18px;
    margin-bottom: 30px;
  }
  ul li:before {
    position: absolute;
    content: '';
    top: 2px;
    left: 5px;
    border: 6px solid transparent;
    border-left: 6px solid #ff7300;
  }
  ul li:last-child {
    margin-bottom: 0;
  }
}

@include for (1500) {
  .maintenance {
    .maintenance__tabs-wrapper {
      .tab-headers {
        padding: 0 20px 20px 20px;
        .tab-header__element {
          padding: 10px 20px;
        }
      }
    }
  }
}

@include for (1300) {
  .maintenance {
    .maintenance__tabs-wrapper {
      .tab-contents {
        padding: 60px 40px 0 40px;
      }
    }
  }
}

@include for (1150) {
  .maintenance {
    padding-bottom: 0;
    .maintenance__tabs-wrapper {
      .tab-headers {
        flex-direction: column;
        align-items: flex-start;
        border-bottom: none;
        padding: 0;
        .tab-headers__triangle {
          display: none;
        }
        .tab-header__element {
          position: relative;
          padding: 20px 30px;
          width: 100%;
          transition: all .2s ease-in-out;
          .element__icon {
            color: #fff;
          }
          .element__text {
            color: #fff;
            text-transform: uppercase;
            font-size: 20px;
          }
          .element__accordion-content-wrapper {
            position: absolute;
            top: 100%;
            left: 0;
            //display: block;
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 20px;
            opacity: 0;
          }
        }
        .tab-header__element:nth-child(1n) {
          background: #c51718;
        }
        .tab-header__element:nth-child(2n) {
          background: #e3470c;
        }
        .tab-header__element:nth-child(3n) {
          background: #ff7300;
        }
        .tab-header__element:hover {
          .element__icon {
            color: #fff;
          }
          .element__text {
            color: #fff;
          }
        }
        .tab-header__element--active {
          .element__icon {
            color: #fff;
          }
          .element__text {
            color: #fff;
          }
        }
      }
      .tab-headers:after {
        display: none;
      }
      .tab-contents {
        display: none;
      }
    }
  }
  .display-none {
    display: none;
  }
}

@include for (700) {
  .maintenance {
    .maintenance__block-title {
      padding: 0 10px;
      text-align: center;
      font-size: 30px;
    }
  }
}
