.main-slider {
  position: relative;
  .slick-list {
    overflow: hidden;
  }
  .slick-track {
    display: flex;
  }
  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    background: transparent;
    border: 1px solid #fff;
    border-radius: 50%;
    color: #fff;
    z-index: 1;
    transition: all .2s ease-in-out;
  }
  .slick-arrow:hover {
    background: rgba(255,255,255, .2);
  }
  .slick-arrow.slick-prev {
    left: 20px;
  }
  .slick-arrow.slick-next {
    right: 20px;
  }

  .slider-item {
    position: relative;
    // .slider-item__img {}
    .slider-item__info {
      position: absolute;
      top: 60%;
      transform: translateY(-50%);
      margin: 0 150px;
      .slider-item__text {
        margin-bottom: 10px;
        text-transform: uppercase;
        font-family: 'CenturyGothic', Arial, Helvetica, sans-serif;
        color: #fff;
        font-size: 30px;
        font-weight: 400;
      }
      .slider-item__title {
        text-transform: uppercase;
        font-family: 'CenturyGothic', Arial, Helvetica, sans-serif;
        color: #fff;
        font-size: 70px;
        font-weight: 400;
        line-height: 90px;
      }
      .slider-item__action-btn {
        // border: 1px solid red;
        margin-top: 20px;
        padding: 25px 70px;
        background: #ff7300;
        text-transform: uppercase;
        color: #fff;
        font-size: 30px;
        border: none;
        border-radius: 50px;
        transition: all .2s ease-in-out;
      }
      .slider-item__action-btn:hover {
        background: #c51719;
      }
    }
  }
}

@include for (1200) {
  .main-slider {
    .slider-item {
      .slider-item__info {
        margin: 0 100px;
        .slider-item__text {
          font-size: 20px;
        }
        .slider-item__title {
          font-size: 40px;
          line-height: 50px;
        }
        .slider-item__action-btn {
          padding: 15px 40px;
        }
      }
    }
  }
}

@include for (900) {
  .main-slider {
    display: none;
  }
}
