.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: linear-gradient(0deg, rgba(103, 45, 42, 0.9), rgba(118, 68, 37, 0.9));
  z-index: 9999;
  overflow-y: auto;
  display: none;
  flex-direction: column;
  .modal__content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }
  .modal__close-btn {
    //border: 1px solid black;
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transform: rotate(45deg);
    .close-btn {
      width: 40px;
      height: 4px;
      background: #fff;
      border-radius: 4px;
      transition: all .2s ease-in-out;
    }
    .close-btn:before {
      position: absolute;
      content: '';
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 4px;
      height: 40px;
      background: #fff;
      border-radius: 4px;
      transition: all .2s ease-in-out;
    }
  }
  .modal__close-btn:hover {
    .close-btn {
      background: #ff7300;
    }
    .close-btn:before {
      background: #ff7300;
    }
  }
}

.modal--active {
  display: flex;
}
