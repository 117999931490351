@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  white-space: normal;
  /* 2 */
  *margin-left: -7px;
  /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
  *height: 13px;
  /* 3 */
  *width: 13px;
  /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type=search] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */
}

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
button,
input,
select,
textarea {
  color: #222;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

* {
  box-sizing: border-box;
}

img {
  width: 100%;
  height: auto;
}

a {
  text-decoration: none;
}

:focus {
  outline: none;
}

@font-face {
  font-family: "fontello";
  src: url("../fonts/fontello/fontello.eot?64075263");
  src: url("../fonts/fontello/fontello.eot?64075263#iefix") format("embedded-opentype"), url("../fonts/fontello/fontello.woff2?64075263") format("woff2"), url("../fonts/fontello/fontello.woff?64075263") format("woff"), url("../fonts/fontello/fontello.ttf?64075263") format("truetype"), url("../fonts/fontello/fontello.svg?64075263#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?64075263#fontello') format('svg');
  }
}
*/
[class^=icon-]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-wrench:before {
  content: "\e800";
} /* '' */
.icon-settings:before {
  content: "\e801";
} /* '' */
.icon-download:before {
  content: "\e802";
} /* '' */
.icon-list:before {
  content: "\e803";
} /* '' */
.icon-clock:before {
  content: "\e804";
} /* '' */
.icon-reload:before {
  content: "\e805";
} /* '' */
.icon-phone:before {
  content: "\e806";
} /* '' */
.icon-search:before {
  content: "\e807";
} /* '' */
.icon-home:before {
  content: "\e808";
} /* '' */
.icon-facebook:before {
  content: "\e809";
} /* '' */
.icon-email:before {
  content: "\e80a";
} /* '' */
.icon-instagram:before {
  content: "\e80b";
} /* '' */
.icon-twitter:before {
  content: "\e80c";
} /* '' */
.icon-close:before {
  content: "\e80d";
} /* '' */
.icon-check:before {
  content: "\e80e";
} /* '' */
@font-face {
  font-family: "CenturyGothic";
  src: local("CenturyGothic"), url(../fonts/CenturyGothic/CenturyGothic.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "CenturyGothic";
  src: local("CenturyGothic-Italic"), url(../fonts/CenturyGothic/CenturyGothic-Italic.ttf) format("truetype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "CenturyGothic";
  src: local("CenturyGothic-Bold"), url(../fonts/CenturyGothic/CenturyGothic-Bold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "CenturyGothic";
  src: local("CenturyGothic-BoldItalic"), url(../fonts/CenturyGothic/CenturyGothic-BoldItalic.ttf) format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "MyriadPro";
  src: local("MyriadPro-Light"), url(../fonts/MyriadPro/MyriadPro-Light.ttf) format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "MyriadPro";
  src: local("MyriadPro-LightSemiExt"), url(../fonts/MyriadPro/MyriadPro-LightSemiExt.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "MyriadPro";
  src: local("MyriadPro"), url(../fonts/MyriadPro/MyriadPro-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "MyriadPro";
  src: local("MyriadPro-Semibold"), url(../fonts/MyriadPro/MyriadPro-Semibold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "MyriadPro";
  src: local("MyriadPro-Bold"), url(../fonts/MyriadPro/MyriadPro-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "MyriadPro";
  src: local("MyriadPro-Black"), url(../fonts/MyriadPro/MyriadPro-Black.ttf) format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
body {
  font-family: "MyriadPro", Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  padding: 20px 0 !important;
  font-weight: 600 !important;
}

h1, .h1 {
  font-size: 40px !important;
}

h2, .h2 {
  font-size: 36px !important;
}

h3, .h3 {
  font-size: 32px !important;
}

h4, .h4 {
  font-size: 28px !important;
}

h5, .h5 {
  font-size: 24px !important;
}

h6, .h6 {
  font-size: 20px !important;
}

a {
  font-family: "MyriadPro", Arial, Helvetica, sans-serif;
  color: #000;
}

button, input, textarea {
  font-family: "MyriadPro", Arial, Helvetica, sans-serif;
}

::placeholder {
  opacity: 0.8;
}

.table-wrapper {
  width: 100%;
  overflow-x: auto;
}

table {
  width: 100%;
}
table tr td {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
table tr td button {
  padding: 5px 10px;
  text-transform: uppercase;
  background: #ff7300;
  color: #fff;
  font-size: 16px;
  border: none;
  border-radius: 50px;
}

.btn {
  padding: 10px 35px;
  border: 2px solid #c51719;
  border-radius: 20px;
  text-align: center;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  text-transform: uppercase;
  background: transparent;
  color: #c51719;
  font-size: 14px;
  font-weight: 600;
}
.btn:hover, .btn:active {
  background: #c51719;
  color: #fff;
}

.header {
  position: relative;
  display: flex;
  flex-direction: column;
  background: #fff;
  z-index: 9;
}
.header .header__top {
  border-bottom: 1px solid #737373;
  transition: all 0.2s ease-in-out;
}
.header .header__top .burger-btn__wrapper {
  align-self: flex-end;
  position: relative;
  display: none;
  margin: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.header .header__top .burger-btn__wrapper .burger-btn {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  transform: translateY(-50%);
  background: #c51719;
  transition: all 0.2s ease-in-out;
}
.header .header__top .burger-btn__wrapper .burger-btn:before {
  position: absolute;
  content: "";
  top: 7px;
  left: 0;
  width: 100%;
  height: 2px;
  background: #c51719;
  transition: all 0.2s ease-in-out;
}
.header .header__top .burger-btn__wrapper .burger-btn:after {
  position: absolute;
  content: "";
  top: -7px;
  left: 0;
  width: 100%;
  height: 2px;
  background: #c51719;
  transition: all 0.2s ease-in-out;
}
.header .header__top .burger-btn__wrapper .burger-btn--active {
  height: 0;
}
.header .header__top .burger-btn__wrapper .burger-btn--active:before {
  top: -1px;
  transform: rotate(-45deg);
}
.header .header__top .burger-btn__wrapper .burger-btn--active:after {
  top: -1px;
  transform: rotate(45deg);
}
.header .header__top .header__top-content {
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1600px;
}
.header .header__top .header__top-content .main-menu .main-menu__list {
  display: flex;
}
.header .header__top .header__top-content .main-menu .main-menu__list .main-menu__list-item {
  display: flex;
}
.header .header__top .header__top-content .main-menu .main-menu__list .main-menu__list-item .list-item__link {
  padding: 10px 15px;
  text-transform: uppercase;
  color: #000;
  font-family: "MyriadPro", Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
}
.header .header__top .header__top-content .main-menu .main-menu__list .main-menu__list-item .list-item__link:hover {
  color: #c51719;
}
.header .header__top .header__top-content .search {
  margin: 10px 0;
  position: relative;
  margin-left: auto;
  width: 100%;
  max-width: 370px;
}
.header .header__top .header__top-content .search .search__input {
  padding: 5px 20px;
  width: 100%;
  border: 1px solid #737373;
  border-radius: 20px;
}
.header .header__top .header__top-content .search .search__action-btn {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  padding: 0;
  width: 20px;
  height: 20px;
  background: transparent;
  border: none;
  overflow: hidden;
  cursor: pointer;
}
.header .header__top .header__top-content .search .search__action-btn .action-btn__icon {
  width: 15px;
  height: 15px;
}
.header .header__top .header__top-content .social-links .social-link {
  margin-left: 10px;
}
.header .header__top .header__top-content .social-links .social-link .social-link__icon {
  width: 25px;
  height: 25px;
  fill: #737373;
  transition: all 0.2s ease-in-out;
}
.header .header__top .header__top-content .social-links .social-link .social-link__icon:hover {
  fill: #c51719;
}
.header .header__top .header__top-content .social-links .social-link:first-child {
  margin-left: 120px;
}
.header .header__center {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  max-width: 1600px;
}
.header .header__center .company-logo {
  max-width: 280px;
}
.header .header__center .contacts {
  display: flex;
  padding: 20px 0;
}
.header .header__center .contacts .contacts__right-block .contacts__item, .header .header__center .contacts .contacts__left-block .contacts__item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.header .header__center .contacts .contacts__right-block .contacts__item .item__icon-wrapper, .header .header__center .contacts .contacts__left-block .contacts__item .item__icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
}
.header .header__center .contacts .contacts__right-block .contacts__item .item__icon-wrapper .item__icon, .header .header__center .contacts .contacts__left-block .contacts__item .item__icon-wrapper .item__icon {
  width: auto;
}
.header .header__center .contacts .contacts__right-block .contacts__item .item__text, .header .header__center .contacts .contacts__left-block .contacts__item .item__text {
  margin-left: 10px;
  color: #000;
  font-weight: 300;
  padding: 0;
}
.header .header__center .contacts .contacts__right-block {
  margin-left: 80px;
}
.header .header__center .contacts .contacts__right-block .contacts__item .item__text {
  font-size: 20px;
  font-weight: 400;
  margin-right: 10px;
}
.header .header__center .contacts .contacts__right-block .contacts__callback .callback__btn {
  padding: 10px 35px;
  border: 2px solid #c51719;
  border-radius: 20px;
  text-align: center;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  text-transform: uppercase;
  background: transparent;
  color: #c51719;
  font-size: 14px;
  font-weight: 600;
}
.header .header__center .contacts .contacts__right-block .contacts__callback .callback__btn:hover {
  background: #c51719;
  color: #fff;
}
.header .header__center .additional-logo {
  max-width: 155px;
}
.header .header__bottom {
  background: #000;
  border-bottom: 2px solid #ff7300;
}
.header .header__bottom .additional-menu {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto;
  width: 100%;
  max-width: 1600px;
}
.header .header__bottom .additional-menu .additional-menu__item {
  padding: 20px;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  font-size: 20px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.header .header__bottom .additional-menu .additional-menu__item a {
  color: #fff;
  transition: all 0.2s ease-in-out;
}
.header .header__bottom .additional-menu .additional-menu__item:hover {
  color: #ff7300;
}
.header .header__bottom .additional-menu .additional-menu__item:hover a {
  color: #ff7300;
}

.form-polit {
  display: grid;
  grid-template-columns: 16px 1fr;
  color: #fff;
  gap: 10px;
  width: 100%;
}
.form-polit a {
  color: #fff;
  text-decoration: underline;
}

@media screen and (max-width: 1640px) {
  .header .header__top {
    padding: 0 20px;
  }
  .header .header__center {
    justify-content: space-around;
  }
  .header .header__center .company-logo {
    width: 50%;
  }
  .header .header__center .contacts {
    flex-direction: column;
  }
  .header .header__center .contacts .contacts__left-block .contacts__item:last-child {
    margin-bottom: 3px;
  }
  .header .header__center .contacts .contacts__right-block {
    margin-left: 0;
  }
  .header .header__center .contacts .contacts__right-block .contacts__item .item__text {
    margin-left: 10px;
    color: #000;
    font-size: 16px;
    font-weight: 300;
  }
  .header .header__center .additional-logo {
    width: 50%;
  }
}
@media screen and (max-width: 1390px) {
  .header .header__top .header__top-content .main-menu .main-menu__list .main-menu__list-item .list-item__link {
    padding: 10px 5px;
  }
}
@media screen and (max-width: 1130px) {
  .header .header__top .header__top-content .social-links .social-link:first-child {
    margin-left: 20px;
  }
  .header .header__center {
    padding: 0 20px;
    justify-content: space-between;
  }
  .header .header__bottom {
    display: none;
  }
}
@media screen and (max-width: 940px) {
  .header .header__top {
    position: fixed;
    top: 10px;
    right: 10px;
    padding: 0;
    width: 50px;
    height: 50px;
    background: #fff;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .header .header__top .burger-btn__wrapper {
    display: flex;
    margin-left: auto;
  }
  .header .header__top .header__top-content {
    display: none;
    flex-direction: column;
    margin-top: 50px;
    height: calc(100% - 100px);
  }
  .header .header__top .header__top-content .main-menu {
    order: 2;
  }
  .header .header__top .header__top-content .main-menu .main-menu__list {
    flex-direction: column;
    align-items: center;
  }
  .header .header__top .header__top-content .main-menu .main-menu__list .main-menu__list-item {
    margin-bottom: 20px;
  }
  .header .header__top .header__top-content .main-menu .main-menu__list .main-menu__list-item .list-item__link {
    font-size: 20px;
  }
  .header .header__top .header__top-content .main-menu .main-menu__list .main-menu__list-item:last-child {
    margin-bottom: 0;
  }
  .header .header__top .header__top-content .search {
    order: 1;
    width: 100%;
    margin: 0 0 40px 0;
  }
  .header .header__top .header__top-content .search .search__input {
    padding: 10px 30px;
    width: 100%;
  }
  .header .header__top .header__top-content .social-links {
    order: 3;
    margin: 40px 0;
  }
  .header .header__top .header__top-content .social-links .social-link:first-child {
    margin-left: 0;
  }
  .header .header__top--active {
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    border-radius: 0;
    box-shadow: none;
  }
  .header .header__center {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
  }
  .header .header__center .company-logo {
    order: 1;
  }
  .header .header__center .additional-logo {
    order: 2;
    margin-left: 100px;
  }
  .header .header__center .contacts {
    order: 3;
    width: 100%;
    align-items: center;
    margin-top: 20px;
  }
  .header .header__center .contacts .contacts__left-block, .header .header__center .contacts .contacts__right-block {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .header .header__center .contacts .contacts__left-block .contacts__item .item__icon-wrapper, .header .header__center .contacts .contacts__right-block .contacts__item .item__icon-wrapper {
    display: none;
  }
  .header .header__center .contacts .contacts__left-block .contacts__item .item__icon-wrapper.viber, .header .header__center .contacts .contacts__right-block .contacts__item .item__icon-wrapper.viber {
    display: block;
  }
  .header .header__center .contacts .contacts__left-block .contacts__item .item__text, .header .header__center .contacts .contacts__right-block .contacts__item .item__text {
    text-align: center;
  }
  .header .header__center .contacts .contacts__right-block .contacts__item .item__text {
    margin-top: 15px;
    font-size: 20px;
    font-weight: 600;
  }
}
@media screen and (max-width: 700px) {
  .header .header__top .header__top-content .search {
    width: calc(100% - 40px);
  }
  .header .header__center {
    padding-top: 40px;
    padding-bottom: 20px;
  }
  .header .header__center .company-logo {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .header .header__center .company-logo a img {
    width: 220px;
  }
  .header .header__center .additional-logo {
    display: none;
  }
}
.main-slider {
  position: relative;
}
.main-slider .slick-list {
  overflow: hidden;
}
.main-slider .slick-track {
  display: flex;
}
.main-slider .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  background: transparent;
  border: 1px solid #fff;
  border-radius: 50%;
  color: #fff;
  z-index: 1;
  transition: all 0.2s ease-in-out;
}
.main-slider .slick-arrow:hover {
  background: rgba(255, 255, 255, 0.2);
}
.main-slider .slick-arrow.slick-prev {
  left: 20px;
}
.main-slider .slick-arrow.slick-next {
  right: 20px;
}
.main-slider .slider-item {
  position: relative;
}
.main-slider .slider-item .slider-item__info {
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  margin: 0 150px;
}
.main-slider .slider-item .slider-item__info .slider-item__text {
  margin-bottom: 10px;
  text-transform: uppercase;
  font-family: "CenturyGothic", Arial, Helvetica, sans-serif;
  color: #fff;
  font-size: 30px;
  font-weight: 400;
}
.main-slider .slider-item .slider-item__info .slider-item__title {
  text-transform: uppercase;
  font-family: "CenturyGothic", Arial, Helvetica, sans-serif;
  color: #fff;
  font-size: 70px;
  font-weight: 400;
  line-height: 90px;
}
.main-slider .slider-item .slider-item__info .slider-item__action-btn {
  margin-top: 20px;
  padding: 25px 70px;
  background: #ff7300;
  text-transform: uppercase;
  color: #fff;
  font-size: 30px;
  border: none;
  border-radius: 50px;
  transition: all 0.2s ease-in-out;
}
.main-slider .slider-item .slider-item__info .slider-item__action-btn:hover {
  background: #c51719;
}

@media screen and (max-width: 1200px) {
  .main-slider .slider-item .slider-item__info {
    margin: 0 100px;
  }
  .main-slider .slider-item .slider-item__info .slider-item__text {
    font-size: 20px;
  }
  .main-slider .slider-item .slider-item__info .slider-item__title {
    font-size: 40px;
    line-height: 50px;
  }
  .main-slider .slider-item .slider-item__info .slider-item__action-btn {
    padding: 15px 40px;
  }
}
@media screen and (max-width: 900px) {
  .main-slider {
    display: none;
  }
}
.services {
  position: relative;
  box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.4);
}
.services .services__top {
  position: relative;
  display: flex;
  margin: -40px auto 0 auto;
  width: 100%;
  max-width: 1600px;
}
.services .services__top .services__top-item {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 30px;
  cursor: pointer;
}
.services .services__top .services__top-item .top-item__icon {
  width: auto;
}
.services .services__top .services__top-item .top-item__title {
  margin-left: 20px;
  text-transform: uppercase;
  color: #fff;
  font-size: 24px;
}
.services .services__top .services__top-item .top-item__links {
  position: absolute;
  bottom: 80%;
  left: 0;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
.services .services__top .services__top-item .top-item__links .links__item {
  display: flex;
}
.services .services__top .services__top-item .top-item__links .links__item .item__link {
  flex: 1 1 auto;
  padding: 20px 80px;
  text-transform: uppercase;
  color: #fff;
  font-size: 20px;
  transition: all 0.2s ease-in-out;
}
.services .services__top .services__top-item .top-item__links .links__item:hover .item__link {
  color: #ff7300;
}
.services .services__top .services__top-item:nth-child(1) {
  background: #c51718;
}
.services .services__top .services__top-item:nth-child(1) .top-item__links {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(197, 23, 24, 0.9));
}
.services .services__top .services__top-item:nth-child(2) {
  background: #e3470c;
}
.services .services__top .services__top-item:nth-child(2) .top-item__links {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(227, 71, 12, 0.9));
}
.services .services__top .services__top-item:nth-child(3) {
  background: #ff7300;
}
.services .services__top .services__top-item:nth-child(3) .top-item__links {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(255, 115, 0, 0.9));
}
.services .services__top .services__top-item:hover .top-item__links {
  bottom: 100%;
  visibility: visible;
  opacity: 1;
}
.services .services__main-content {
  display: flex;
  flex-direction: column;
  padding: 60px 0;
}
.services .services__main-content .main-content__block-title {
  align-self: center;
  font-family: "CenturyGothic", Arial, Helvetica, sans-serif;
  font-size: 45px;
  font-weight: 600;
}
.services .services__main-content .main-content__services-items {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  margin-top: 40px;
  width: 100%;
  max-width: 1000px;
}
.services .services__main-content .main-content__services-items.center {
  text-align: center;
}
.services .services__main-content .main-content__services-items .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 40px;
  width: calc(33.33% - 7px);
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;
}
.services .services__main-content .main-content__services-items .item .item__icon {
  width: auto;
  height: 60px;
}
.services .services__main-content .main-content__services-items .item .item__title {
  margin-top: 30px;
  color: #000;
  font-family: "CenturyGothic", Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
}
.services .services__main-content .main-content__services-items .item .item__more-info-text {
  margin-top: 20px;
  color: #000;
  font-size: 18px;
  font-weight: 200;
  transition: all 0.2s ease-in-out;
}
.services .services__main-content .main-content__services-items .item:nth-child(3n) {
  margin-right: 0;
}
.services .services__main-content .main-content__services-items .item:hover {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.2);
}
.services .services__main-content .main-content__services-items .item:hover .item__title {
  color: #c51718;
}
.services .services__main-content .main-content__services-items .item:hover .item__more-info-text {
  color: #ff7300;
}

@media screen and (max-width: 1040px) {
  .services .services__main-content {
    padding: 60px 20px;
  }
}
@media screen and (max-width: 1000px) {
  .services .services__main-content .main-content__services-items .item {
    width: calc(50% - 5px);
  }
  .services .services__main-content .main-content__services-items .item:nth-child(3n) {
    margin-right: 10px;
  }
  .services .services__main-content .main-content__services-items .item:nth-child(2n) {
    margin-right: 0;
  }
}
@media screen and (max-width: 960px) {
  .services .services__top {
    margin-top: 0;
    flex-direction: column;
  }
  .services .services__top .services__top-item {
    padding: 15px 30px;
    transition: all 0.2s ease-in-out;
  }
  .services .services__top .services__top-item .top-item__links {
    top: calc(100% + 1px);
    overflow: hidden;
    z-index: 1;
  }
  .services .services__top .services__top-item--active .top-item__links {
    visibility: visible;
    opacity: 1;
  }
  .services .services__top .services__top-item:nth-child(1) {
    background: #c51718;
  }
  .services .services__top .services__top-item:nth-child(1) .top-item__links {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(197, 23, 24, 0.9));
  }
  .services .services__top .services__top-item:nth-child(2) {
    background: #e3470c;
  }
  .services .services__top .services__top-item:nth-child(2) .top-item__links {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(227, 71, 12, 0.9));
  }
  .services .services__top .services__top-item:nth-child(3) {
    background: #ff7300;
  }
  .services .services__top .services__top-item:nth-child(3) .top-item__links {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(255, 115, 0, 0.9));
  }
}
@media screen and (max-width: 700px) {
  .services .services__main-content .main-content__block-title {
    padding: 0 10px;
    text-align: center;
    font-size: 30px;
  }
  .services .services__main-content .main-content__services-items .item {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 6px 0px rgba(0, 0, 0, 0.2);
  }
  .services .services__main-content .main-content__services-items .item:nth-child(1n) {
    margin-right: 0;
  }
  .services .services__main-content .main-content__services-items .item:hover {
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 6px 0px rgba(0, 0, 0, 0.2);
  }
}
.software {
  display: flex;
  flex-direction: column;
  background: #ebebeb;
  padding: 60px 0;
}
.software .software__block-title {
  align-self: center;
  font-family: "CenturyGothic", Arial, Helvetica, sans-serif;
  font-size: 45px;
  font-weight: 600;
}
.software .software__items {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  margin-top: 40px;
  width: 100%;
  max-width: 1100px;
}
.software .software__items .item {
  border: 1px solid red;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 40px 20px;
  width: calc(33.33% - 7px);
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
}
.software .software__items .item .item__img {
  width: auto;
  height: 110px;
}
.software .software__items .item .item__title {
  margin-top: 30px;
  padding: 0 10px;
  text-align: center;
  color: #000;
  font-family: "CenturyGothic", Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
}
.software .software__items .item .item__description {
  margin-top: 30px;
  text-align: center;
  color: #000;
  font-size: 18px;
  font-weight: 200;
}
.software .software__items .item .item__cost {
  margin-top: 40px;
  color: #c51718;
  font-size: 20px;
  font-weight: 700;
}
.software .software__items .item .item__more-info-text {
  margin-top: 20px;
  color: #000;
  font-size: 18px;
  font-weight: 200;
  transition: all 0.2s ease-in-out;
}
.software .software__items .item:nth-child(3n) {
  margin-right: 0;
}
.software .software__items .item:hover {
  border: 1px solid transparent;
  box-shadow: none;
}
.software .software__items .item:hover .item__title {
  color: #c51718;
}
.software .software__items .item:hover .item__more-info-text {
  color: #ff7300;
}
.software .newSoftware__items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}
.software .newSoftware__items .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100% !important;
}
.software .newSoftware__items .item picture img.item__img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
}

@media screen and (max-width: 1150px) {
  .software {
    padding: 60px 20px;
  }
  .software .software__items .item {
    width: calc(50% - 5px);
  }
  .software .software__items .item:nth-child(3n) {
    margin-right: 10px;
  }
  .software .software__items .item:nth-child(2n) {
    margin-right: 0;
  }
  .software .newSoftware__items {
    width: 100%;
    margin: 0 !important;
  }
}
@media screen and (max-width: 700px) {
  .software .software__block-title {
    padding: 0 10px;
    text-align: center;
    font-size: 30px;
  }
  .software .software__items .item {
    width: 100%;
    box-shadow: 0 0 6px 0px rgba(0, 0, 0, 0.2);
  }
  .software .software__items .item:nth-child(1n) {
    margin-right: 0;
  }
}
@media screen and (max-width: 700px) {
  .software .newSoftware__items {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
  }
}
.request-callback {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 60px 0;
  background: url(/assets/templates/images/index/request-callback-bg/request-callback-bg.jpg);
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  z-index: 1;
}
.request-callback .request-callback__block-title {
  align-self: center;
  text-align: center;
  color: #fff;
  font-family: "CenturyGothic", Arial, Helvetica, sans-serif;
  font-size: 45px;
  font-weight: 600;
}
.request-callback .callback-form {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.request-callback .callback-form .callback-form__left-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 370px;
}
.request-callback .callback-form .callback-form__left-block .form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
}
.request-callback .callback-form .callback-form__left-block .form-group span {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 0 10px;
  color: #ffffff;
}
.request-callback .callback-form .callback-form__left-block .form-group span span {
  margin-bottom: 0;
}
.request-callback .callback-form .callback-form__left-block .form-group .callback-form__input {
  padding: 10px;
  width: 100%;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 20px;
  color: #fff;
  font-weight: 200;
  transition: all 0.2s ease-in-out;
}
.request-callback .callback-form .callback-form__left-block .form-group .callback-form__input:focus {
  border: 2px solid #ff7300;
}
.request-callback .callback-form .callback-form__left-block .form-group .callback-form__input::placeholder {
  color: #fff;
}
.request-callback .callback-form .callback-form__left-block .callback-form__action-btn {
  margin-top: 10px;
  padding: 10px 40px;
  background: #ff7300;
  border: none;
  border-radius: 20px;
  text-transform: uppercase;
  color: #fff;
  font-size: 20px;
  transition: all 0.2s ease-in-out;
}
.request-callback .callback-form .callback-form__left-block .callback-form__action-btn:hover {
  background: #c51718;
}
.request-callback .callback-form .callback-form__left-block .hidden {
  display: none;
}
.request-callback .callback-form .callback-form__right-block {
  margin-left: 30px;
  width: 100%;
  max-width: 370px;
}
.request-callback .callback-form .callback-form__right-block .callback-form__info-text {
  color: #fff;
  font-size: 18px;
  font-weight: 200;
}
.request-callback .callback-form .callback-form__right-block .callback-form__phone {
  margin: 5px 0;
  color: #fff;
  font-size: 24px;
  font-weight: 400;
}
.request-callback .callback-form .callback-form__right-block .callback-form__phone a {
  color: #fff;
}

@media screen and (max-width: 900px) {
  .request-callback .request-callback__block-title {
    padding: 0 10px;
    text-align: center;
    font-size: 30px;
  }
  .request-callback .callback-form {
    flex-direction: column;
    align-items: center;
  }
  .request-callback .callback-form .callback-form__right-block {
    order: 1;
    margin-left: 0;
    text-align: center;
  }
  .request-callback .callback-form .callback-form__left-block {
    order: 2;
    margin-top: 20px;
  }
}
@media screen and (max-width: 450px) {
  .request-callback .callback-form .callback-form__left-block .callback-form__input {
    width: calc(100% - 40px);
  }
  .request-callback .callback-form .callback-form__right-block {
    padding: 0 20px;
  }
}
.advantage__wrapper {
  position: relative;
  box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.4);
}
.advantage__wrapper .advantage {
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  padding: 60px 0;
  width: 100%;
  max-width: 1600px;
}
.advantage__wrapper .advantage .advantage__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 30px;
  width: 100%;
}
.advantage__wrapper .advantage .advantage__item .item__img {
  width: auto;
  height: 45px;
}
.advantage__wrapper .advantage .advantage__item .item__title {
  margin-top: 30px;
  max-width: 230px;
  text-align: center;
  font-family: "CenturyGothic", Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 600;
}
.advantage__wrapper .advantage .advantage__item .item__description {
  margin-top: 20px;
  text-align: center;
}

@media screen and (max-width: 1200px) {
  .advantage__wrapper .advantage {
    flex-wrap: wrap;
  }
  .advantage__wrapper .advantage .advantage__item {
    margin-top: 40px;
    width: 50%;
  }
}
@media screen and (max-width: 700px) {
  .advantage__wrapper .advantage {
    padding: 40px 0;
  }
  .advantage__wrapper .advantage .advantage__item {
    width: 100%;
  }
  .advantage__wrapper .advantage .advantage__item:first-child {
    margin-top: 0;
  }
}
.maintenance {
  display: flex;
  flex-direction: column;
  padding: 60px 0;
  background: #ebebeb;
}
.maintenance .maintenance__block-title {
  align-self: center;
  font-family: "CenturyGothic", Arial, Helvetica, sans-serif;
  font-size: 45px;
  font-weight: 600;
}
.maintenance .maintenance__tabs-wrapper {
  margin-top: 40px;
}
.maintenance .maintenance__tabs-wrapper .tab-headers {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 60px 20px 60px;
  border-bottom: 1px solid #ff7300;
  overflow: hidden;
}
.maintenance .maintenance__tabs-wrapper .tab-headers .tab-headers__triangle {
  position: absolute;
  bottom: -7px;
  left: 0;
  width: 15px;
  height: 15px;
  background: #ff7300;
  transform: rotate(-45deg);
  transition: all 0.2s ease-in-out;
}
.maintenance .maintenance__tabs-wrapper .tab-headers .tab-header__element {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 40px;
  cursor: pointer;
}
.maintenance .maintenance__tabs-wrapper .tab-headers .tab-header__element .element__icon {
  color: #737373;
  font-size: 26px;
  transition: all 0.2s ease-in-out;
}
.maintenance .maintenance__tabs-wrapper .tab-headers .tab-header__element .element__text {
  margin-left: 10px;
  font-size: 20px;
  transition: all 0.2s ease-in-out;
}
.maintenance .maintenance__tabs-wrapper .tab-headers .tab-header__element .element__accordion-content-wrapper {
  display: none;
}
.maintenance .maintenance__tabs-wrapper .tab-headers .tab-header__element:hover .element__icon {
  color: #ff7300;
}
.maintenance .maintenance__tabs-wrapper .tab-headers .tab-header__element:hover .element__text {
  color: #ff7300;
}
.maintenance .maintenance__tabs-wrapper .tab-headers .tab-header__element--active .element__icon {
  color: #ff7300;
}
.maintenance .maintenance__tabs-wrapper .tab-headers .tab-header__element--active .element__text {
  color: #ff7300;
}
.maintenance .maintenance__tabs-wrapper .tab-contents {
  margin: 0 auto;
  padding: 60px 0 0 0;
  width: 100%;
  max-width: 1300px;
  transition: all 0.2s ease-in-out;
}
.maintenance .maintenance__tabs-wrapper .tab-contents .tab-content__element {
  display: none;
}
.maintenance .maintenance__tabs-wrapper .tab-contents .tab-content__element .element__list .list__item {
  display: flex;
  margin-bottom: 20px;
}
.maintenance .maintenance__tabs-wrapper .tab-contents .tab-content__element .element__list .list__item .item__text {
  font-size: 22px;
}
.maintenance .maintenance__tabs-wrapper .tab-contents .tab-content__element .element__list .list__item .divider {
  flex: 1 1 auto;
  margin: 0 10px;
  min-width: 40px;
  height: 18px;
  border-bottom: 3px dotted #737373;
}
.maintenance .maintenance__tabs-wrapper .tab-contents .tab-content__element .element__list .list__item .item__cost {
  color: #c51718;
  font-size: 22px;
  font-weight: 700;
}
.maintenance .maintenance__tabs-wrapper .tab-contents .tab-content__element .element__list .list__item:last-child {
  margin-bottom: 0;
}
.maintenance .maintenance__tabs-wrapper .tab-contents .tab-content__element:first-child {
  display: block;
}
.maintenance ul {
  list-style: none;
}
.maintenance ul li {
  position: relative;
  padding-left: 30px;
  font-size: 18px;
  margin-bottom: 30px;
}
.maintenance ul li:before {
  position: absolute;
  content: "";
  top: 2px;
  left: 5px;
  border: 6px solid transparent;
  border-left: 6px solid #ff7300;
}
.maintenance ul li:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 1500px) {
  .maintenance .maintenance__tabs-wrapper .tab-headers {
    padding: 0 20px 20px 20px;
  }
  .maintenance .maintenance__tabs-wrapper .tab-headers .tab-header__element {
    padding: 10px 20px;
  }
}
@media screen and (max-width: 1300px) {
  .maintenance .maintenance__tabs-wrapper .tab-contents {
    padding: 60px 40px 0 40px;
  }
}
@media screen and (max-width: 1150px) {
  .maintenance {
    padding-bottom: 0;
  }
  .maintenance .maintenance__tabs-wrapper .tab-headers {
    flex-direction: column;
    align-items: flex-start;
    border-bottom: none;
    padding: 0;
  }
  .maintenance .maintenance__tabs-wrapper .tab-headers .tab-headers__triangle {
    display: none;
  }
  .maintenance .maintenance__tabs-wrapper .tab-headers .tab-header__element {
    position: relative;
    padding: 20px 30px;
    width: 100%;
    transition: all 0.2s ease-in-out;
  }
  .maintenance .maintenance__tabs-wrapper .tab-headers .tab-header__element .element__icon {
    color: #fff;
  }
  .maintenance .maintenance__tabs-wrapper .tab-headers .tab-header__element .element__text {
    color: #fff;
    text-transform: uppercase;
    font-size: 20px;
  }
  .maintenance .maintenance__tabs-wrapper .tab-headers .tab-header__element .element__accordion-content-wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    opacity: 0;
  }
  .maintenance .maintenance__tabs-wrapper .tab-headers .tab-header__element:nth-child(1n) {
    background: #c51718;
  }
  .maintenance .maintenance__tabs-wrapper .tab-headers .tab-header__element:nth-child(2n) {
    background: #e3470c;
  }
  .maintenance .maintenance__tabs-wrapper .tab-headers .tab-header__element:nth-child(3n) {
    background: #ff7300;
  }
  .maintenance .maintenance__tabs-wrapper .tab-headers .tab-header__element:hover .element__icon {
    color: #fff;
  }
  .maintenance .maintenance__tabs-wrapper .tab-headers .tab-header__element:hover .element__text {
    color: #fff;
  }
  .maintenance .maintenance__tabs-wrapper .tab-headers .tab-header__element--active .element__icon {
    color: #fff;
  }
  .maintenance .maintenance__tabs-wrapper .tab-headers .tab-header__element--active .element__text {
    color: #fff;
  }
  .maintenance .maintenance__tabs-wrapper .tab-headers:after {
    display: none;
  }
  .maintenance .maintenance__tabs-wrapper .tab-contents {
    display: none;
  }
  .display-none {
    display: none;
  }
}
@media screen and (max-width: 700px) {
  .maintenance .maintenance__block-title {
    padding: 0 10px;
    text-align: center;
    font-size: 30px;
  }
}
.send-question {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 60px 0;
  background: url(/assets/templates/images/send-question/send-question-bg.jpg);
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  z-index: 1;
}
.send-question .send-question__block-title {
  align-self: center;
  text-align: center;
  color: #fff;
  font-family: "CenturyGothic", Arial, Helvetica, sans-serif;
  font-size: 45px;
  font-weight: 600;
}
.send-question .send-question__form {
  display: flex;
  flex-wrap: wrap;
  margin: 40px auto 0 auto;
  width: 100%;
  max-width: 770px;
}
.send-question .send-question__form .form-group {
  display: flex;
  flex-direction: column;
  width: calc(50% - 15px);
  margin-bottom: 10px;
}
.send-question .send-question__form .form-group span {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 0 10px;
  color: #ffffff;
}
.send-question .send-question__form .form-group span span {
  margin-bottom: 0;
}
.send-question .send-question__form .form-group .form__input {
  padding: 10px;
  width: 100%;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 20px;
  color: #fff;
  font-weight: 200;
  transition: all 0.2s ease-in-out;
}
.send-question .send-question__form .form-group .form__input:focus {
  border: 2px solid #ff7300;
}
.send-question .send-question__form .form-group .form__input::placeholder {
  color: #fff;
}
.send-question .send-question__form .form-group .form__textarea {
  padding: 10px;
  width: 100%;
  min-height: 100px;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 20px;
  color: #fff;
  font-weight: 200;
  transition: all 0.2s ease-in-out;
}
.send-question .send-question__form .form-group .form__textarea:focus {
  border: 2px solid #ff7300;
}
.send-question .send-question__form .form-group .form__textarea::placeholder {
  color: #fff;
}
.send-question .send-question__form .form-group:nth-child(3) {
  margin-left: 30px;
}
.send-question .send-question__form .form-group:nth-child(4) {
  width: 100%;
}
.send-question .send-question__form .hidden {
  display: none;
}
.send-question .send-question__form .form__action-btn {
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 20px;
  background: transparent;
  color: #fff;
  font-size: 20px;
  border: none;
  transition: all 0.2s ease-in-out;
}
.send-question .send-question__form .form__action-btn:hover {
  color: #ff7300;
}

@media screen and (max-width: 800px) {
  .send-question {
    padding: 60px 20px;
  }
}
@media screen and (max-width: 700px) {
  .send-question .send-question__block-title {
    padding: 0 10px;
    text-align: center;
    font-size: 30px;
  }
  .send-question .send-question__form .form-group {
    width: 100%;
  }
  .send-question .send-question__form .form-group:nth-child(3) {
    margin-left: 0;
  }
  .send-question .send-question__form .form__input {
    width: 100%;
  }
  .send-question .send-question__form .form__input:first-child {
    margin-right: 0;
  }
}
.additional-info {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 60px 20px;
  background: #ffffff;
  box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.4);
  z-index: 2;
}
.additional-info .additional-info__block-title {
  align-self: center;
  margin-bottom: 40px;
  text-align: center;
  font-family: "CenturyGothic", Arial, Helvetica, sans-serif;
  font-size: 45px;
  font-weight: 600;
}
.additional-info .additional-info__content {
  margin: 0 auto;
  width: 100%;
  max-width: 1300px;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
}

@media screen and (max-width: 700px) {
  .additional-info .additional-info__block-title {
    padding: 0 10px;
    text-align: center;
    font-size: 30px;
  }
}
.brands__slider {
  background: #ebebeb;
  padding: 50px 0;
}

.footer .map {
  position: relative;
  height: 600px;
}
.footer .map .overflow-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 600px;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1;
  transition: opacity 0.2s ease-in-out 0.2s;
}
.footer .footer__bottom {
  position: relative;
  display: flex;
  flex-direction: column;
  background: #000;
  z-index: 1;
}
.footer .footer__bottom .contacts {
  display: flex;
  margin: -40px auto 0 auto;
  width: 100%;
  max-width: 1300px;
}
.footer .footer__bottom .contacts .contacts__item {
  display: flex;
  align-items: center;
  padding: 20px 30px;
  width: 100%;
}
.footer .footer__bottom .contacts .contacts__item .item__img {
  width: auto;
  height: 45px;
}
.footer .footer__bottom .contacts .contacts__item .item__text {
  margin-left: 20px;
  color: #fff;
  font-size: 18px;
}
.footer .footer__bottom .contacts .contacts__item .item__text a {
  color: #fff;
}
.footer .footer__bottom .contacts .contacts__item:nth-child(1) {
  background: #c51718;
}
.footer .footer__bottom .contacts .contacts__item:nth-child(2) {
  background: #e3470c;
}
.footer .footer__bottom .contacts .contacts__item:nth-child(2) .item__text {
  font-size: 20px;
}
.footer .footer__bottom .contacts .contacts__item:nth-child(3) {
  background: #ff7300;
}
.footer .footer__bottom .copyright {
  margin: 20px 0;
}
.footer .footer__bottom .copyright .copyright__text {
  text-align: center;
  color: #fff;
  font-size: 14px;
  margin-bottom: 5px;
  padding: 0;
}
.footer .footer__bottom .copyright .copyright__text:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 1300px) {
  .footer .footer__bottom .contacts {
    margin-top: 0;
  }
  .footer .footer__bottom .contacts .contacts__item {
    width: auto;
    flex: 1 1 auto;
  }
}
@media screen and (max-width: 950px) {
  .footer .map {
    height: 400px;
  }
  .footer .footer__bottom .contacts {
    flex-direction: column;
  }
  .footer .footer__bottom .contacts .contacts__item {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 20px;
  }
  .footer .footer__bottom .contacts .contacts__item .item__img {
    width: 46px;
  }
  .footer .footer__bottom .contacts .contacts__item .item__text {
    width: 100%;
    max-width: 270px;
  }
}
.jGrowl.top-right {
  right: 40px !important;
  top: 20px !important;
}

#jGrowl .jGrowl-notification {
  position: relative;
  width: 100%;
  max-width: 360px;
  padding: 15px 25px 15px 15px;
  box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.2) !important;
  border: 2px solid #fff !important;
  border-radius: 10px;
}

#jGrowl .jGrowl-closer {
  padding: 10px;
  width: 100%;
  max-width: 360px;
  box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.2) !important;
  border: 2px solid #fff !important;
  border-radius: 10px;
}

#jGrowl .jGrowl-notification .jGrowl-close {
  position: absolute;
  right: 4px;
  top: 4px;
  font-size: 16px;
  color: #fff !important;
}

#jGrowl .jGrowl-notification .jGrowl-message {
  font-size: 18px;
  color: #fff !important;
}

#jGrowl .af-message-error {
  background: #c51718;
}

#jGrowl .af-message-success {
  background: #8ab933 !important;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: linear-gradient(0deg, rgba(103, 45, 42, 0.9), rgba(118, 68, 37, 0.9));
  z-index: 9999;
  overflow-y: auto;
  display: none;
  flex-direction: column;
}
.modal .modal__content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}
.modal .modal__close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transform: rotate(45deg);
}
.modal .modal__close-btn .close-btn {
  width: 40px;
  height: 4px;
  background: #fff;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}
.modal .modal__close-btn .close-btn:before {
  position: absolute;
  content: "";
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 4px;
  height: 40px;
  background: #fff;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}
.modal .modal__close-btn:hover .close-btn {
  background: #ff7300;
}
.modal .modal__close-btn:hover .close-btn:before {
  background: #ff7300;
}

.modal--active {
  display: flex;
}

.contact-us__request-call {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.contact-us__request-call .request-call__block-title {
  align-self: center;
  text-align: center;
  color: #fff;
  font-family: "CenturyGothic", Arial, Helvetica, sans-serif;
  font-size: 45px;
  font-weight: 600;
}
.contact-us__request-call .request-call__form {
  display: flex;
  flex-wrap: wrap;
  margin: 40px auto 0 auto;
  width: 100%;
  max-width: 770px;
}
.contact-us__request-call .request-call__form .form-group {
  display: flex;
  flex-direction: column;
  width: calc(50% - 15px);
  margin-bottom: 10px;
}
.contact-us__request-call .request-call__form .form-group span {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 0 10px;
  color: #ffffff;
}
.contact-us__request-call .request-call__form .form-group span span {
  margin-bottom: 0;
}
.contact-us__request-call .request-call__form .form-group .form__input {
  padding: 10px;
  width: 100%;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 20px;
  color: #fff;
  font-weight: 200;
  transition: all 0.2s ease-in-out;
}
.contact-us__request-call .request-call__form .form-group .form__input:focus {
  border: 2px solid #ff7300;
}
.contact-us__request-call .request-call__form .form-group .form__input::placeholder {
  color: #fff;
}
.contact-us__request-call .request-call__form .form-group .form__textarea {
  padding: 10px;
  width: 100%;
  min-height: 150px;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 20px;
  color: #fff;
  font-weight: 200;
  transition: all 0.2s ease-in-out;
}
.contact-us__request-call .request-call__form .form-group .form__textarea:focus {
  border: 2px solid #ff7300;
}
.contact-us__request-call .request-call__form .form-group .form__textarea::placeholder {
  color: #fff;
}
.contact-us__request-call .request-call__form .form-group:nth-child(3) {
  margin-left: 30px;
}
.contact-us__request-call .request-call__form .form-group:nth-child(4) {
  width: 100%;
}
.contact-us__request-call .request-call__form .hidden {
  display: none;
}
.contact-us__request-call .request-call__form .form__action-btn {
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 30px;
  background: #ff7300;
  color: #fff;
  font-size: 20px;
  border: none;
  border-radius: 25px;
  transition: all 0.2s ease-in-out;
}
.contact-us__request-call .request-call__form .form__action-btn:hover {
  background: #c51718;
}

@media screen and (max-width: 600px) {
  .contact-us__request-call .request-call__block-title {
    font-size: 30px;
  }
  .contact-us__request-call .request-call__form {
    margin-top: 30px;
  }
  .contact-us__request-call .request-call__form .form-group {
    width: 100%;
  }
  .contact-us__request-call .request-call__form .form-group:nth-child(3) {
    margin-left: 0;
  }
}