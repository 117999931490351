
// Подключение шрифта с иконками
@font-face {
  font-family: 'fontello';
  src: url('../fonts/fontello/fontello.eot?64075263');
  src: url('../fonts/fontello/fontello.eot?64075263#iefix') format('embedded-opentype'),
  url('../fonts/fontello/fontello.woff2?64075263') format('woff2'),
  url('../fonts/fontello/fontello.woff?64075263') format('woff'),
  url('../fonts/fontello/fontello.ttf?64075263') format('truetype'),
  url('../fonts/fontello/fontello.svg?64075263#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?64075263#fontello') format('svg');
  }
}
*/

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-wrench:before { content: '\e800'; } /* '' */
.icon-settings:before { content: '\e801'; } /* '' */
.icon-download:before { content: '\e802'; } /* '' */
.icon-list:before { content: '\e803'; } /* '' */
.icon-clock:before { content: '\e804'; } /* '' */
.icon-reload:before { content: '\e805'; } /* '' */
.icon-phone:before { content: '\e806'; } /* '' */
.icon-search:before { content: '\e807'; } /* '' */
.icon-home:before { content: '\e808'; } /* '' */
.icon-facebook:before { content: '\e809'; } /* '' */
.icon-email:before { content: '\e80a'; } /* '' */
.icon-instagram:before { content: '\e80b'; } /* '' */
.icon-twitter:before { content: '\e80c'; } /* '' */
.icon-close:before { content: '\e80d'; } /* '' */
.icon-check:before { content: '\e80e'; } /* '' */







// Подключение шрифтов Century Gothic
@font-face {
  font-family: "CenturyGothic";
  src: local("CenturyGothic"), // проверять наличие шрифта на компьютере перед загрузкой
       url(../fonts/CenturyGothic/CenturyGothic.ttf) format("truetype");
  font-weight: 400; // 100 || 200 || 300 || 400 || 500 || 600 || 700 || 800 || 900
  font-style: normal; // normal || italic
  font-display: swap; // auto || block || swap || fallback || optional
}

@font-face {
  font-family: "CenturyGothic";
  src: local("CenturyGothic-Italic"), // проверять наличие шрифта на компьютере перед загрузкой
       url(../fonts/CenturyGothic/CenturyGothic-Italic.ttf) format("truetype");
  font-weight: 400; // 100 || 200 || 300 || 400 || 500 || 600 || 700 || 800 || 900
  font-style: italic; // normal || italic
  font-display: swap; // auto || block || swap || fallback || optional
}

@font-face {
  font-family: "CenturyGothic";
  src: local("CenturyGothic-Bold"), // проверять наличие шрифта на компьютере перед загрузкой
       url(../fonts/CenturyGothic/CenturyGothic-Bold.ttf) format("truetype");
  font-weight: 600; // 100 || 200 || 300 || 400 || 500 || 600 || 700 || 800 || 900
  font-style: normal; // normal || italic
  font-display: swap; // auto || block || swap || fallback || optional
}

@font-face {
  font-family: "CenturyGothic";
  src: local("CenturyGothic-BoldItalic"), // проверять наличие шрифта на компьютере перед загрузкой
       url(../fonts/CenturyGothic/CenturyGothic-BoldItalic.ttf) format("truetype");
  font-weight: 600; // 100 || 200 || 300 || 400 || 500 || 600 || 700 || 800 || 900
  font-style: italic; // normal || italic
  font-display: swap; // auto || block || swap || fallback || optional
}

// Подключение шрифтов Myriad Pro

@font-face {
  font-family: "MyriadPro";
  src: local("MyriadPro-Light"), // проверять наличие шрифта на компьютере перед загрузкой
       url(../fonts/MyriadPro/MyriadPro-Light.ttf) format("truetype");
  font-weight: 200; // 100 || 200 || 300 || 400 || 500 || 600 || 700 || 800 || 900
  font-style: normal; // normal || italic
  font-display: swap; // auto || block || swap || fallback || optional
}

@font-face {
  font-family: "MyriadPro";
  src: local("MyriadPro-LightSemiExt"), // проверять наличие шрифта на компьютере перед загрузкой
       url(../fonts/MyriadPro/MyriadPro-LightSemiExt.ttf) format("truetype");
  font-weight: 300; // 100 || 200 || 300 || 400 || 500 || 600 || 700 || 800 || 900
  font-style: normal; // normal || italic
  font-display: swap; // auto || block || swap || fallback || optional
}

@font-face {
  font-family: "MyriadPro";
  src: local("MyriadPro"), // проверять наличие шрифта на компьютере перед загрузкой
       url(../fonts/MyriadPro/MyriadPro-Regular.ttf) format("truetype");
  font-weight: 400; // 100 || 200 || 300 || 400 || 500 || 600 || 700 || 800 || 900
  font-style: normal; // normal || italic
  font-display: swap; // auto || block || swap || fallback || optional
}

@font-face {
  font-family: "MyriadPro";
  src: local("MyriadPro-Semibold"), // проверять наличие шрифта на компьютере перед загрузкой
       url(../fonts/MyriadPro/MyriadPro-Semibold.ttf) format("truetype");
  font-weight: 600; // 100 || 200 || 300 || 400 || 500 || 600 || 700 || 800 || 900
  font-style: normal; // normal || italic
  font-display: swap; // auto || block || swap || fallback || optional
}

@font-face {
  font-family: "MyriadPro";
  src: local("MyriadPro-Bold"), // проверять наличие шрифта на компьютере перед загрузкой
       url(../fonts/MyriadPro/MyriadPro-Bold.ttf) format("truetype");
  font-weight: 700; // 100 || 200 || 300 || 400 || 500 || 600 || 700 || 800 || 900
  font-style: normal; // normal || italic
  font-display: swap; // auto || block || swap || fallback || optional
}

@font-face {
  font-family: "MyriadPro";
  src: local("MyriadPro-Black"), // проверять наличие шрифта на компьютере перед загрузкой
       url(../fonts/MyriadPro/MyriadPro-Black.ttf) format("truetype");
  font-weight: 900; // 100 || 200 || 300 || 400 || 500 || 600 || 700 || 800 || 900
  font-style: normal; // normal || italic
  font-display: swap; // auto || block || swap || fallback || optional
}
