.request-callback {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 60px 0;
  background: url(/assets/templates/images/index/request-callback-bg/request-callback-bg.jpg);
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  z-index: 1;

  .request-callback__block-title {
    align-self: center;
    text-align: center;
    color: #fff;
    font-family: 'CenturyGothic', Arial, Helvetica, sans-serif;
    font-size: 45px;
    font-weight: 600;
  }

  .callback-form {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    .callback-form__left-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 370px;
      .form-group {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 10px;
        span {
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;
          padding: 0 10px;
          color: #ffffff;
          span {
            margin-bottom: 0;
          }
        }
        .callback-form__input {
          //margin-bottom: 10px;
          padding: 10px;
          width: 100%;
          background: transparent;
          border: 2px solid #fff;
          border-radius: 20px;
          color: #fff;
          font-weight: 200;
          transition: all .2s ease-in-out;
        }
        .callback-form__input:focus {
          border: 2px solid #ff7300;
        }
        .callback-form__input::placeholder {
          color: #fff;
        }
      }
      .callback-form__action-btn {
        margin-top: 10px;
        padding: 10px 40px;
        background: #ff7300;
        border: none;
        border-radius: 20px;
        text-transform: uppercase;
        color: #fff;
        font-size: 20px;
        transition: all .2s ease-in-out;
      }
      .callback-form__action-btn:hover {
        background: #c51718;
      }
      .hidden {
          display: none;
      }
    }
    .callback-form__right-block {
      margin-left: 30px;
      width: 100%;
      max-width: 370px;
      .callback-form__info-text {
        color: #fff;
        font-size: 18px;
        font-weight: 200;
      }
      .callback-form__phone {
        margin: 5px 0;
        color: #fff;
        font-size: 24px;
        font-weight: 400;
        a {
          color: #fff;
        }
      }
    }
  }

}

@include for (900) {
  .request-callback {
    .request-callback__block-title {
      padding: 0 10px;
      text-align: center;
      font-size: 30px;
    }
    .callback-form {
      flex-direction: column;
      align-items: center;
      .callback-form__right-block {
        order: 1;
        margin-left: 0;
        text-align: center;
      }
      .callback-form__left-block {
        order: 2;
        margin-top: 20px;
      }
    }
  }
}

@include for (450) {
  .request-callback {
    .callback-form {
      .callback-form__left-block {
        .callback-form__input {
          width: calc(100% - 40px);
        }
      }
      .callback-form__right-block {
        padding: 0 20px;
      }
    }
  }
}
