body {
  font-family: 'MyriadPro', Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  padding: 20px 0 !important;
  font-weight: 600 !important;
}

h1, .h1 {
  font-size: 40px !important;
}

h2, .h2 {
  font-size: 36px !important;
}

h3, .h3 {
  font-size: 32px !important;
}

h4, .h4 {
  font-size: 28px !important;
}

h5, .h5 {
  font-size: 24px !important;
}

h6, .h6 {
  font-size: 20px !important;
}


a {
  font-family: 'MyriadPro', Arial, Helvetica, sans-serif;
  color: #000;
}

button, input, textarea {
  font-family: 'MyriadPro', Arial, Helvetica, sans-serif;
}

::placeholder {
  opacity: .8;
}

.table-wrapper {
  //border: 1px solid red;
  width: 100%;
  overflow-x: auto;
}

table {
  width: 100%;

  tr {
    td {
      padding: 10px;
      border: 1px solid rgba(0, 0, 0, 0.1);

      button {
        padding: 5px 10px;
        text-transform: uppercase;
        background: #ff7300;
        color: #fff;
        font-size: 16px;
        border: none;
        border-radius: 50px;
      }
    }
  }
}

%container {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 20px;
}

.btn {
  padding: 10px 35px;
  border: 2px solid #c51719;
  border-radius: 20px;
  text-align: center;
  transition: all .2s ease-in-out;
  cursor: pointer;
  text-transform: uppercase;
  background: transparent;
  color: #c51719;
  font-size: 14px;
  font-weight: 600;

  &:hover, &:active {
    background: #c51719;
    color: #fff;
  }
}